var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-overlay',{attrs:{"color":"white","absolute":_vm.absolute,"opacity":_vm.opacity}}),(_vm.user !== null)?_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.groupDialog),callback:function ($$v) {_vm.groupDialog=$$v},expression:"groupDialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.user.fName)+", which group would you like work in right now? ")]),_c('br'),_c('v-card-text',[_c('v-row',_vm._l((_vm.groups),function(group){return _c('v-col',{key:group.id},[_c('v-card',{staticClass:"mx-auto my-12 d-flex justify-center",attrs:{"color":_vm.selectedGroup === group.name ? 'primary' : 'grey lighten-2',"height":"100","elevation":"10"},on:{"click":function($event){return _vm.directToRole(group)}}},[_c('v-card-title',{class:_vm.selectedGroup === group.name ? 'white--text' : 'black--text'},[_vm._v(" "+_vm._s(group.name)+" ")])],1)],1)}),1)],1),_c('v-card-actions')],1)],1):_vm._e(),(_vm.user !== null)?_c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.roleDialog),callback:function ($$v) {_vm.roleDialog=$$v},expression:"roleDialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.user.fName)+", what would you like to do right now? ")]),_c('br'),_c('v-card-text',[_c('v-row',{attrs:{"align-center":""}},_vm._l((_vm.roles),function(role){return _c('v-col',{key:role.id,staticClass:"d-flex justify-center align-center"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":_vm.selectedRole.type == 'Student' && _vm.selectedRole.id == role.id
                  ? '#EE5044'
                  : _vm.selectedRole.type == 'Tutor' && _vm.selectedRole.id == role.id
                  ? '#196CA2'
                  : 'grey lighten-2',"x-large":""},on:{"click":function($event){_vm.selectedRole = role;
                _vm.saveGroupRoleSelection(role);}}},[_vm._v(" "+_vm._s(role.type)+" ")])],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":function($event){_vm.roleDialog = false;
            _vm.groupDialog = true;
            _vm.selectedGroup = Object;}}},[_vm._v(" Back ")]),_c('v-spacer')],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }