var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-card-title',{staticClass:"text-h4 font-weight-bold pt-4 pb-6 pl-0 accent--text"},[_vm._v(_vm._s(`${_vm.user.selectedGroup} Requests`)+" "),_c('InformationComponent',{attrs:{"message":'View requests from people in ' +
          _vm.user.selectedGroup +
          ' and mark them as In-Progress or Completed appropriately.'}})],1),_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"accent","elevation":"2"},on:{"click":function($event){_vm.hideCompleted = !_vm.hideCompleted;
            _vm.filterCompleted();}}},[_vm._v(" "+_vm._s(_vm.hideCompleted ? "Show " : "Hide ")+" Completed ")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.requestDialog),callback:function ($$v) {_vm.requestDialog=$$v},expression:"requestDialog"}},[_c('RequestDialogBody',{attrs:{"sent-request":_vm.selectedRequest,"person-role-id":_vm.id,"sent-bool":true},on:{"closeRequestDialog":function($event){_vm.requestDialog = false},"saveOrAddRequest":_vm.saveRequest}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.filteredRequests,"items-per-page":50},on:{"click:row":_vm.rowClick}})],1),_c('v-snackbar',{attrs:{"rounded":"pill"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":_vm.alertType === 'success'
              ? 'green'
              : _vm.alertType === 'warning'
              ? 'yellow'
              : 'error',"text":""},on:{"click":function($event){_vm.showAlert = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alert)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }