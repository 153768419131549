var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-card-title',{staticClass:"text-h4 font-weight-bold pt-4 pb-6 pl-0 pr-0 accent--text"},[_vm._v("Hello, "+_vm._s(_vm.user.fName)+"! "),_c('InformationComponent',{attrs:{"message":_vm.message}}),_c('v-spacer'),_c('v-spacer'),_c('v-card-title',{staticClass:"text-right pt-0 pb-0 pl-0 pr-0 accent--text"},[_vm._v(_vm._s(_vm.user.selectedRole.type))])],1),_c('v-snackbar',{attrs:{"rounded":"pill"},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":_vm.alertType === 'success'
              ? 'green'
              : _vm.alertType === 'warning'
              ? 'yellow'
              : 'error',"text":""},on:{"click":function($event){_vm.showAlert = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alert)+" ")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.appointmentDialog),callback:function ($$v) {_vm.appointmentDialog=$$v},expression:"appointmentDialog"}},[_c('AppointmentDialogBody',{attrs:{"sent-appointment":_vm.selectedAppointment},on:{"closeAppointmentDialog":function($event){_vm.appointmentDialog = false},"doneWithAppointment":function($event){_vm.appointmentDialog = false;
          _vm.getAppointments();}}})],1),(_vm.hasRole('Student'))?_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.requestDialog),callback:function ($$v) {_vm.requestDialog=$$v},expression:"requestDialog"}},[_c('RequestDialogBody',{attrs:{"sent-request":_vm.selectedRequest,"person-role-id":_vm.user.selectedRole.personRoleId,"sent-bool":false},on:{"closeRequestDialog":function($event){_vm.requestDialog = false},"saveOrAddRequest":_vm.addRequest}})],1):_vm._e(),(_vm.approved)?_c('span',[_c('v-row',{attrs:{"fill-height":""}},[_c('v-col',[_c('v-card',{staticClass:"d-flex justify-center ml-4 mr-4 mb-6",attrs:{"height":"100","color":_vm.hasRole('Student') ? '#EE5044' : '#196CA2'},on:{"click":function($event){return _vm.handleRedundantNavigation(
                'calendar',
                _vm.user.selectedRole.personRoleId
              )}}},[_c('v-card-title',{staticClass:"justify-center white--text"},[_vm._v(" View Calendar ")])],1)],1),_c('v-col',[_c('v-card',{staticClass:"d-flex justify-center ml-4 mr-4 mb-6",attrs:{"height":"100","color":_vm.hasRole('Student') ? '#F8C545' : '#63BAC0'},on:{"click":function($event){_vm.hasRole('Student')
                ? (_vm.requestDialog = true)
                : _vm.handleRedundantNavigation(
                    'tutorAddAvailability',
                    _vm.user.selectedRole.personRoleId
                  )}}},[_c('v-card-title',{staticClass:"justify-center white--text"},[_vm._v(" "+_vm._s(_vm.hasRole("Student") ? "Make A Request" : "Manage Availability")+" ")])],1)],1)],1),_c('v-card',[_c('v-card-title',[_vm._v(" Upcoming Appointments for "+_vm._s(_vm.user.selectedGroup)+" as a "+_vm._s(_vm.user.selectedRole.type)+" "),_c('v-spacer'),_c('InformationComponent',{attrs:{"message":"Click on an appointment to view information, make changes, or\n            cancel."}})],1),_c('v-data-table',{attrs:{"headers":_vm.upcomingHeaders,"items":_vm.upcomingAppointments},on:{"click:row":_vm.openUpcoming},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.color}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status))])])]}}],null,true)})],1),_c('br'),_c('v-card',[_c('v-card-title',[_vm._v(" Provide Feedback for Appointments as a "+_vm._s(_vm.user.selectedRole.type)+" "),_c('v-spacer'),_c('InformationComponent',{attrs:{"message":"Click on an appointment to provide feedback."}})],1),_c('v-data-table',{attrs:{"headers":_vm.upcomingHeaders,"items":_vm.feedbackAppointments},on:{"click:row":_vm.openFeedback},scopedSlots:_vm._u([{key:`item.status`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.color}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.status))])])]}}],null,true)})],1)],1):(!_vm.disabled && !_vm.approved)?_c('span',[_c('h4',[_vm._v("Pending supervisor's approval...")])]):_c('span',[_c('h4',[_vm._v(" This role for "+_vm._s(_vm.group.name)+" has been disabled. Please contact the group admin for further questions. ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }