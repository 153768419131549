var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-card-title',{staticClass:"text-h4 font-weight-bold pt-4 pb-6 pl-0 pr-0 accent--text"},[_vm._v("Hello, "+_vm._s(_vm.user.fName)+"! "),_c('InformationComponent',{attrs:{"message":_vm.headerMessage}}),_c('v-spacer'),_c('v-card-title',{staticClass:"text-right pt-0 pb-0 pl-0 pr-0 accent--text"},[_vm._v("Admin")])],1),_c('v-alert',{attrs:{"dismissible":"","type":_vm.alertType},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(_vm._s(_vm.alert))]),_c('br'),(_vm.approved)?_c('div',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"justify":"center"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Upcoming Appointment Hours - "+_vm._s(_vm.user.selectedGroup)+" "),_c('v-spacer'),_c('InformationComponent',{attrs:{"message":"View a breakdown of appointment hours for last week, this\n                week, and next week."}})],1),_c('apexchart',{ref:"chart",attrs:{"width":"700","type":"bar","options":_vm.chartOptions,"series":_vm.series}})],1)],1),_c('v-col',{attrs:{"justify":"center"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',{staticClass:"mx-auto my-3 justify-center",on:{"click":function($event){return _vm.handleRedundantNavigation(
                  'requestList',
                  _vm.user.selectedRole.personRoleId
                )}}},[_c('v-card-title',[_vm._v(" Student Requests "),_c('v-spacer'),_c('InformationComponent',{attrs:{"message":"Click here to view requests."}})],1),_c('apexchart',{attrs:{"width":"380","type":"pie","options":_vm.pieOptions,"series":_vm.pieSeries}}),_c('br')],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',{staticClass:"mx-auto my-5 justify-center",on:{"click":function($event){return _vm.handleRedundantNavigation(
                  'adminApprove',
                  _vm.user.selectedRole.personRoleId
                )}}},[_c('v-card-title',[_vm._v(" Tutor Applications "),_c('v-spacer'),_c('InformationComponent',{attrs:{"message":"Click here to view applications."}})],1),_c('v-card-text',{staticClass:"text-center"},[_c('h1',[_vm._v(_vm._s(_vm.applicationNum))])])],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-card',{staticClass:"tutor"},[_c('v-card-title',[_vm._v(" Tutors For Week Starting "+_vm._s(_vm.currentWeek)+" "),_c('v-spacer'),_c('InformationComponent',{attrs:{"message":"View a breakdown of the appointment hours for each tutor."}})],1),_c('v-data-table',{attrs:{"headers":_vm.tutorTable,"search":_vm.search,"items":_vm.tutors,"items-per-page":50}})],1),_c('br')],1),_c('v-col',[_c('v-card',{staticClass:"tutor"},[_c('v-card-title',[_vm._v(" Topics For Week Starting "+_vm._s(_vm.currentWeek)+" "),_c('v-spacer'),_c('InformationComponent',{attrs:{"message":"View a breakdown of the appointment hours for each topic."}})],1),_c('v-data-table',{attrs:{"headers":_vm.topicTable,"search":_vm.search,"items":_vm.topics,"items-per-page":50}})],1)],1)],1)],1):(!_vm.disabled && !_vm.approved)?_c('div',[_c('h4',[_vm._v("Pending supervisor's approval...")])]):_c('div',[_c('h4',[_vm._v(" This role for "+_vm._s(_vm.group.name)+" has been disabled. Please contact a group admin for further questions. ")])]),_c('br'),_c('br')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }