var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.isAdminView)?_c('v-card-title',{class:(_vm.request.status === 'Received'
        ? 'error'
        : _vm.request.status === 'In-Progress'
        ? 'yellow'
        : _vm.request.status === 'Completed'
        ? 'darkblue'
        : 'primary') + ' white--text mb-2 headline'},[_vm._v(_vm._s(`Request from ${_vm.request.fullName}`))]):(!_vm.isAdminView)?_c('v-card-title',{staticClass:"primary white--text mb-4 headline"},[_vm._v(_vm._s(`Send A Request to ${_vm.user.selectedGroup}`))]):_vm._e(),(_vm.isAdminView)?_c('v-card-subtitle',{class:(_vm.request.status === 'Received'
        ? 'error'
        : _vm.request.status === 'In-Progress'
        ? 'yellow'
        : _vm.request.status === 'Completed'
        ? 'darkblue'
        : 'primary') + ' white--text pb-2 mb-2'},[_vm._v(" "+_vm._s(`Received on ${_vm.formatReadableDate(_vm.request.createdAt)} at ${ _vm.request.time }`)+" ")]):_vm._e(),_c('v-card-text',[(_vm.isAdminView)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"mt-2 align-center d-flex"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-email-outline")]),_c('b',{staticClass:"mr-2"},[_vm._v("Email: ")]),_c('a',{attrs:{"href":'mailto:' + _vm.request.person.email}},[_vm._v(_vm._s(_vm.request.person.email))])],1)]),(_vm.request.person.textOptIn)?_c('v-col',[_c('div',{staticClass:"mt-2 align-center d-flex"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-phone")]),_c('b',{staticClass:"mr-2"},[_vm._v("Phone Number: ")]),_vm._v(" "+_vm._s(_vm.request.person.phoneNum)+" ")],1)]):_vm._e()],1):_vm._e(),_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.problems,"label":_vm.isAdminView ? 'Problem' : 'Why are you making this request?',"prepend-icon":"mdi-alert-outline","readonly":_vm.isAdminView,"required":""},on:{"change":function($event){return _vm.resetEverything()}},model:{value:(_vm.request.problem),callback:function ($$v) {_vm.$set(_vm.request, "problem", $$v)},expression:"request.problem"}}),(_vm.showCourseNumber)?_c('v-text-field',{attrs:{"label":_vm.isAdminView
          ? 'Course Number'
          : 'Which course do you need tutoring for?',"prepend-icon":"mdi-bookshelf","readonly":_vm.isAdminView},model:{value:(_vm.request.courseNum),callback:function ($$v) {_vm.$set(_vm.request, "courseNum", $$v)},expression:"request.courseNum"}}):_vm._e(),(_vm.showTopic)?_c('v-select',{attrs:{"items":_vm.topics,"item-text":"name","item-value":"id","label":_vm.isAdminView ? 'Topic' : 'Which topic corresponds to your course?',"prepend-icon":_vm.isAdminView ? 'mdi-book-outline' : 'mdi-book-edit-outline',"readonly":_vm.isAdminView},model:{value:(_vm.request.topicId),callback:function ($$v) {_vm.$set(_vm.request, "topicId", $$v)},expression:"request.topicId"}}):_vm._e(),(_vm.showDescription)?_c('v-textarea',{attrs:{"label":_vm.request.problem === _vm.problems[0]
          ? 'Which times would work for you?'
          : _vm.request.problem === _vm.problems[1]
          ? 'Do you have any additional comments about the missing topic?'
          : _vm.request.problem === _vm.problems[2]
          ? 'Please describe the issue you would like to report.'
          : _vm.request.problem === _vm.problems[3]
          ? 'What would you like to request?'
          : '',"rows":"2","persistent-hint":"","prepend-icon":_vm.isAdminView ? 'mdi-comment-outline' : 'mdi-comment-edit-outline',"readonly":_vm.isAdminView},model:{value:(_vm.request.description),callback:function ($$v) {_vm.$set(_vm.request, "description", $$v)},expression:"request.description"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey white--text"},on:{"click":function($event){return _vm.$emit('closeRequestDialog')}}},[_vm._v(" "+_vm._s(_vm.isAdminView ? "Close" : "Cancel")+" ")]),(_vm.request.status !== 'Received')?_c('v-btn',{attrs:{"color":"error white--text"},on:{"click":function($event){_vm.request.status = 'Received';
        _vm.saveChanges = true;}}},[_vm._v(" Mark Received ")]):_vm._e(),(_vm.request.status !== 'In-Progress')?_c('v-btn',{attrs:{"color":"yellow white--text"},on:{"click":function($event){_vm.request.status = 'In-Progress';
        _vm.saveChanges = true;}}},[_vm._v(" Mark In-Progress ")]):_vm._e(),(_vm.request.status !== 'Completed')?_c('v-btn',{attrs:{"color":"darkblue white--text"},on:{"click":function($event){_vm.request.status = 'Completed';
        _vm.saveChanges = true;}}},[_vm._v(" Mark Completed ")]):_vm._e(),_c('v-btn',{attrs:{"color":"accent","disabled":_vm.isAdminView
          ? !_vm.saveChanges
          : _vm.request.problem === '' || _vm.request.description === ''},on:{"click":function($event){return _vm.saveOrAddRequest()}}},[_vm._v(_vm._s(_vm.isAdminView ? "Save Changes" : "Send Request"))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }