var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.user !== null)?_c('v-dialog',{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.roleDialog),callback:function ($$v) {_vm.roleDialog=$$v},expression:"roleDialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.user.fName)+", what best describes your situation right now? ")]),_c('br'),_c('v-card-text',[_c('v-row',{attrs:{"align-center":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{attrs:{"color":_vm.roleSelect == 'Student' ? 'primary' : 'grey lighten-2',"x-large":""},on:{"click":function($event){_vm.roleSelect = 'Student'}}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-chair-school")]),_vm._v(" Student ")],1)],1),_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{attrs:{"color":_vm.roleSelect == 'Tutor' ? 'primary' : 'grey lighten-2',"x-large":""},on:{"click":function($event){_vm.roleSelect = 'Tutor'}}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v("mdi-human-male-board-poll")]),_vm._v(" Tutor ")],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_vm._v(" I want tutoring in some of my classes. ")]),_c('v-col',{staticClass:"d-flex justify-center align-center"},[_vm._v(" I want to help provide quality tutoring to other students. ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey white--text"},on:{"click":function($event){return _vm.$emit('closeRegistrationComponent')}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){_vm.roleDialog = false;
            _vm.groupDialog = true;
            _vm.haveRoleAlready();}}},[_vm._v(" Continue ")])],1)],1)],1):_vm._e(),(_vm.user !== null)?_c('v-dialog',{attrs:{"persistent":"","max-width":"1000"},model:{value:(_vm.groupDialog),callback:function ($$v) {_vm.groupDialog=$$v},expression:"groupDialog"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.user.fName)+", which group would you like to be a "+_vm._s(_vm.roleSelect.toLowerCase())+" in? ")]),_c('br'),_c('v-card-text',[_c('v-row',_vm._l((_vm.groups),function(group){return _c('v-col',{key:group.id},[_c('v-card',_vm._g({attrs:{"disabled":group.haveRole,"color":_vm.groupSelect === group.id ? 'primary' : 'grey lighten-2',"height":"200","elevation":"10"}},
                !group.haveRole
                  ? { click: () => (_vm.groupSelect = group.id) }
                  : {}
              ),[_c('v-card-title',{class:_vm.groupSelect === group.id ? 'white--text' : 'black--text'},[_vm._v(" "+_vm._s(group.name)+" ")]),_c('v-card-text',{class:_vm.groupSelect === group.id ? 'white--text' : 'black--text'},[_vm._v(" "+_vm._s(group.description)+" "),_c('v-overlay',{attrs:{"absolute":_vm.absolute,"opacity":_vm.opacity,"value":group.haveRole}},[_c('h3',[_vm._v(" "+_vm._s(group.sentenceHaveRole)+" ")])])],1)],1)],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){_vm.roleDialog = true;
            _vm.groupDialog = false;
            _vm.groupSelect = '';}}},[_vm._v(" Back ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey white--text"},on:{"click":function($event){return _vm.$emit('closeRegistrationComponent')}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"accent","disabled":_vm.groupSelect === ''},on:{"click":function($event){return _vm.savePersonRoles()}}},[_vm._v(" Continue ")])],1)],1)],1):_vm._e(),(_vm.openSelect)?_c('GroupViewComponent'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }