<template>
  <v-container>
    <v-card-title
      class="text-h4 font-weight-bold pt-4 pb-6 pl-0 pr-0 accent--text"
      >Uh, oh!
      <v-spacer></v-spacer>
      <v-card-title class="text-right pt-0 pb-0 pl-0 pr-0 accent--text"
        >The page you are looking for has been moved or does not
        exist.</v-card-title
      >
    </v-card-title>
  </v-container>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>
