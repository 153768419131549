var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"primary white--text mb-6"},[_vm._v(_vm._s(_vm.isEdit ? "Edit " + _vm.personRole.type + " Role for " + _vm.personName : "Add a Role for " + _vm.personName))]),_c('v-card-text',[(!_vm.isEdit)?_c('v-select',{attrs:{"id":"role","items":_vm.groupRoles,"item-text":"type","item-value":"id","return-object":"","label":"Role"},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}}):_vm._e(),_c('v-select',{attrs:{"id":"status","disabled":_vm.isDisabled,"items":_vm.isDisabled
          ? [{ value: 'disabled', statusName: 'disabled' }]
          : _vm.statuses,"item-text":"statusName","return-object":"","label":"Status"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}})],1),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),(_vm.isEdit)?_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.isDisabled
          ? _vm.changePersonRoleStatus('approved')
          : (_vm.disableConfirmDialog = true)}}},[_vm._v(_vm._s(_vm.isDisabled ? "Enable Role" : "Disable Role"))]):_vm._e(),_c('v-btn',{attrs:{"color":"grey white--text"},on:{"click":function($event){return _vm.$emit('closePersonRoleDialog')}}},[_vm._v(" "+_vm._s(_vm.isEdit ? "Discard Changes" : "Cancel")+" ")]),_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.saveOrAddPersonRole()}}},[_vm._v(_vm._s(_vm.isEdit ? "Save Changes" : "Add Role"))])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"750px"},model:{value:(_vm.disableConfirmDialog),callback:function ($$v) {_vm.disableConfirmDialog=$$v},expression:"disableConfirmDialog"}},[_c('DeleteConfirmationComponent',{attrs:{"type":"personrole","item":{ ..._vm.personRole, ...{ personName: _vm.personName } }},on:{"handleReturningCancel":function($event){_vm.disableConfirmDialog = false},"handleReturningSuccess":function($event){_vm.isDisabled
          ? _vm.changePersonRoleStatus('approved')
          : _vm.changePersonRoleStatus('disabled')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }